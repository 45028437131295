import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Link } from '@inertiajs/react'
import { useIntl } from 'react-intl'


const AppTopbar = forwardRef((props, ref) => {
    // const { auth: { user } } = usePage().props
    const intl = useIntl()
    const menubuttonRef = useRef(null)
    const topbarmenuRef = useRef(null)
    const topbarmenubuttonRef = useRef(null)

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current,
    }))

    return (
        <>
            <div className='flex w-full layout-topbar justify-content-between'>
                <div className={'col'}>
                    <Link
                        href='/'
                        className='layout-topbar-logo'>
                        <img
                            className={'w-12rem ml-2 mt-2'}
                            src={`/images/logo/logo_menu_orange.svg`}
                            alt='logo' />
                    </Link>
                </div>
            </div>
        </>
    )
})

export default AppTopbar
